.main{
    flex-direction:row !important;
    justify-content:space-between !important;
    // width:"80%",
    margin:auto;
    border:1px solid blue !important;

}
.main2{
    flex-direction:column
}