.MuiInput-underline::before{
    border-bottom: none ;
 }
 .MuiInput-underline::after{
    border-bottom: none ;
 }
 .calaneder .MuiInput-underline::after{
    border-bottom: none !important;
 }
 .calaneder .MuiInput-underline::before{
    border-bottom: none !important;
 }

  .calaneder .MuiFormControl-marginNormal{
   margin-top: 0px !important;
   margin-bottom: 0px !important ;
 }