.maincontainer{
    background-color: white ;
          width: 97% ;
          margin: auto ;
          height: 1000px ;
          margin-top: 10px;
          padding: 10px;
}
.approve{
    width: 88px;
height: 34px;
margin: auto !important;
box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
border-radius: 10px;
}
.personaldetails{
    font-family: 'Lato' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}
.currentplan{
    background: #FFEDD7;
border-radius: 10px 0px 0px 0px;
// height:62px
}
.currentplan1{
    background: #FFEDD7;
border-radius: 0px 10px 0px 0px;
// height:55px;
margin-left: -4px;

}
@media all and (max-width:360px){

    .griddata{
      margin-top:20px !important;
    }
  }
  @media all and (max-width:660px){

    .griddata{
      margin-top:20px !important;
    }
  }

.plannames{
    font-family: 'Lato'!important;
font-style: normal;
font-weight: 700 !important;
font-size: 20px !important;
line-height: 27px;
padding: 10px !important;
color: #000000;
}
.planstack{
    background: #FFFFFF;
    border-radius: 10px;
width: 95%;
margin: auto;
padding-bottom: 20px;

}
.planType{

background: #EFF3FF;
border-radius: 8px;
padding: 2px 8px 2px 8px;
}
.plantypename{
    font-family: 'Lato' !important;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
padding:5px;
color: #000000;

}
.plantypename1{
    font-family: 'Lato' !important;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
/* identical to box height */
margin-right: 20px;

color: #FF8D00;
}
.reneviewplan{
    font-style: normal;
    font-family: 'Lato' !important;
font-weight: 600 !important;
font-size: 14px !important;
line-height: 19px;


color: #000000;
}
.clickbutton{
    font-family: 'Lato' !important;
font-style: normal;
font-weight: 400 !important;
font-size: 12px !important;
line-height: 16px;
color: rgba(0, 0, 0, 0.6) !important;

}
.renew{
    width: 110px;
height: 38px;
border: none;
color:white;
background: #FF8D00;
border-radius: 10px;
font-size: 16px;
font-family: 'Lato' !important;
}
.addedplan{
    background: #FFFFFF;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
padding:10px;
margin-top:20px;
}

.basic{
    height: 20px;
width: 68px;
border-radius: 8px;
background: #EFF3FF;

}
.reniewsubmit{
    width: 113px;
height: 38px;
border: none;
background: #FF8D00;
border-radius: 10px;
margin: auto;
font-family: 'Lato';
}
.basicfont{
    font-family: Lato !important;
font-size: 12px;
font-weight: 700;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #17024B;
margin-left: 10px !important;
}
.active{
    box-sizing: border-box;
width: 69.54px;
height: 21.95px;

border: 1px solid #000000;
border-radius: 4px;
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
}
.price{
    font-family: 'Lato';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;

color: #FF8D00;
}
.expires{
    font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;

color: #868686;
}
.invoice{
    font-family: 'Lato';
font-size: 12px;
font-weight: 600;
color: #FF8D00;

}
.shop{
    font-family: Open Sans;
font-size: 16px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
margin-top: 5px;
color:white

}
.imglist{
    width: 70px;
    height: 70px;
    margin: "auto";
    margin-top: 10px;
};
.box1 >.imglist{
    width: 70px;
    height: 70px;
    margin-top: 10px;
};
.box1{
    Width: 96px;
    height: 96px;
    border-radius: 50;
    overflow: hidden;
    font-family:'Open Sans';
    margin: auto;
};
.sidename{
    font-size: 14px !important;
    font-family: Open Sans!important;
    font-weight: 400 !important;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    overflow-wrap: break-word;
    text-transform: capitalize;
    margin-left: 4px;



};
//  check box fontfamily
.css-ahj2mt-MuiTypography-root{
    font-family: Lato !important;
}
.mobilenumber{
    font-size: 14px !important ;
    font-family: Open Sans !important;
    font-weight: 400;
    margin:auto !important
}
.heading{
    font-size: 14px !important;
    font-family: 'Open Sans' !important;
    font-weight: 700 !important;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    overflow-wrap: break-word;
    text-transform: capitalize;

}
.mainheading{
    font-size: 16px !important;
    font-family: 'Open Sans' !important;
    font-weight: 700 !important;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin-bottom: 7px;
    text-transform: capitalize;
    overflow-wrap: break-word;

}
.textborder{
    width: 100%;
    border: 0.499378px solid #BDBDBD;
    margin-top: 10px;

}
.textborder .MuiOutlinedInput-notchedOutline{
    border:none !important;
    box-shadow: 0px 1px 2px #c8c8c8;
    border-radius: 6px !important;
}

.MuiInput-underline::before{
    border-bottom: 2px solid black !important;
 }
 .MuiInput-underline::after{
    border-bottom: 2px solid orange!important;
 }
 .calaneder .MuiInput-underline::after{
    border-bottom:2px solid orange !important;
 }
 .calaneder .MuiInput-underline::before{
    border-bottom: 2px solid black !important;
 }
 .querydate .MuiFormControl-marginNormal{
    margin-top: 5px !important;
    // margin-bottom: 0px !important ;
  }