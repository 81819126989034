.totalusers{
  height:530px;
}
  
@media all and (max-width:960px){

  .subscriptions{
    margin-top:30px !important
  }
  .totalusers{
    margin-top:30px !important

  }
  .typeofusers{
    margin-top:30px !important

  }
  .totalusers{
    height:auto
  }
}
@media all and (max-width:320px){

  .cat-mainheading{
    font-size: 16px;
  }
}
@media only screen and (max-width:1024px){
.maindiv{
  margin-right: -130px !important;
}
  
}
@media only screen and (max-width:1440px){
  .maindiv{
      margin-left: 100px !important;
  }
      
    }