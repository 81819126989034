//  index csss
.globalname{
    font-weight: 700 !important;
font-size: 20px !important;
line-height: 24px;
/* identical to box height */


color: #000000 !important;
}





//  global statistics css part

.global{
    border-radius: 8px;
    color:white
}
.name{
    font-weight: 400 !important;
font-size: 16px !important;
line-height: 19px;
color: #FFFFFF ;
}
.count{
font-weight: 600 !important;
font-size: 36px !important;
line-height: 43px;

color: #FFFFFF ;
}


// Total Subscriptions css
.subcount{
    font-weight: 700 !important;
font-size: 32px !important;
margin:auto !important;
color: #1A1A1A !important;
}
.form{
    font-weight: 400 !important;
font-size: 14px !important;
color: rgba(0, 0, 0, 0.6) !important;
} 


//  Total users

.totalname{
font-weight: 700 !important;
font-size: 20px !important;
color: #000000 !important;
}



// registered users

.gridname{
    font-weight: 400 !important;
font-size: 20px !important;
color: #FF8D00 !important;
padding:5px !important;
// text-align:center !important;
padding-left: 70px !important;
}

.keys{
font-weight: 600 !important;
font-size: 17px !important;
color: #666666 !important;
}


//  Total users graphs 
.usersgraph{
color:#FF8D00 !important;
font-size: 16px !important;
}