* {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
  }
  
  .nav {
    display: flex;
    align-items: center;
    background-color: #f8f7f8;
    height: 70px;
    justify-content: space-between;
  }
  .logo-container {
    padding-left: 4rem;
    cursor: pointer;
  }
  .logo {
    border-radius: 50%;
  }
  
  .container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .heading {
    padding: 1rem 0px;
    font-size: 2rem;
    font-weight: 700;
  }

  .login{
    margin: 16px;
    width: 112px;
    height: 43px;
    background: #17024B;
    color: white;
    font-size: 17px;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .tag-line {
    font-size: 32px;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }
  
  .appstore-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .appstore-container img {
    cursor: pointer;
  }
  .appstore-container img:hover {
    scale: 1.05;
  }
  .btn {
    font-weight: 700;
    width: 6rem;
    height: 2rem;
    border-radius: 20px;
    background-color: #ff8d00;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
  .btn:hover {
    background-color: #ed880c;
  }
  
  .ellipse {
    position: relative;
    width: 100%;
    margin-top: 15rem;
    height: 420px;
    border-radius: 50%;
    background-color: #fff3e3;
  }
  .position {
    position: absolute;
    bottom: 50%;
  }
  .footer-container {
    padding: 2rem 0;
    background-color: #16004d;
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-heading {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
  
  .terms {
    display: flex;
    flex-direction: column;
  }
  .terms a,
  i a {
    color: white;
    text-decoration: none;
  }
  .terms a:hover,
  i a:hover {
    color: white;
    text-decoration: underline;
  }
  .terms a:visited {
    color: white;
  }
  
  .contact {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #6d6a6a;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .position {
    position: relative;
  }
  