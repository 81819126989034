.name1{
    font-family: 'Lato' !important;
font-style: normal !important;
font-weight: 700 !important;
font-size: 14px !important;
line-height: 17px !important;
/* identical to box height */
margin-top: 5px !important;


}
.ratings{
 font-family: 'Lato' !important;
 font-style: normal !important;
 font-weight: 600 !important;
 font-size: 12px !important;
//  line-height: 14px !important;
  // margin-top: 3px !important;
color:#000000 !important;
}
.feedback{
    font-family: 'Lato' !important;
font-style: normal !important;
font-weight: 500;
font-size: 12px !important;
line-height: 14px;
padding:5px !important;
color: #000000;
}
.avgrating{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 600 !important;
font-size: 18px !important;
line-height: 25px;
/* identical to box height */


color: #17024B !important;

}
.plantypename1{
  font-family: 'Lato';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
/* identical to box height */
margin-right: 20px;

color: #FF8D00;
}