.planbox{
    background: #EFF3FF;
border-radius: 8px;
}
.active{
    width:80px;
height: 23px;
border: 1px solid #000000;
border-radius: 4px;
justify-content: center;

}
.plantypename{
    font-family: 'Lato' !important;
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
padding:3px;
color: #17024B;

}
.plantypename1{
font-family: 'Lato' !important;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #FF8D00;
}
.planbox{
    padding:2px 8px 2px 8px;
}